<script setup lang="ts">
import { ref, watch } from 'vue';
import strings from './surveys.strings';
import { getRawHTML } from './utils';

const { angry, sad, slightlyHappy, warmSmile, heartEyes } = strings;
const props = defineProps<{
  platformSpecific: string;
  linkUrl: string;
}>();
const { linkUrl } = props;
const body = ref<string>('');
watch(
  () => props.platformSpecific,
  () => {
    getBody();
  }
);

const getBody = () => {
  body.value = `<tr>
                <td style="border:none;">
                 <h3 style="font-weight:bold;font-family:Arial,sans-serif;color:#373f46;margin:0 0 10px 0;text-align:center">
                  How satisfied are you with our service?
                 </h3>
                 <table cellpadding="5" style="border:none;margin:auto;max-width:500px;width:fit-content; border-collapse: collapse; padding: 0;">
                  <tr>
                   ${[1, 2, 3, 4, 5]
                     .map((rating) => {
                       const emojis = [
                         { srcGifId: `1f620`, alt: angry },
                         { srcGifId: `1f61e`, alt: sad },
                         { srcGifId: `1f642`, alt: slightlyHappy },
                         { srcGifId: `263a_fe0f`, alt: warmSmile },
                         { srcGifId: `1f60d`, alt: heartEyes },
                       ];
                       const { srcGifId, alt } = emojis[rating - 1];
                       return `<td style="text-align:center;padding:0 10px;border:none;">
                       <a href="${linkUrl}?rating=${rating}${props.platformSpecific}" style="display:inline-block;margin:0;">
                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/${srcGifId}/512.gif" alt="${alt}" style="display:block;margin:auto;width:60px;max-width:100%;height:auto;"/>
                       </a>
                     </td>`;
                     })
                     .join('')}
                   </tr>
                  </table>
                 </td>
                </tr>`;
};
</script>

<template>
  {{ getRawHTML(body) }}
</template>
