import { openDialog } from '@mfl/common-components';
import { Survey } from '@msl/surveys-gateway-sdk';

import AddEditSurveyDialog from './add-edit-survey-dialog.vue';
import strings from './surveys.strings';

export async function addEditSurvey(
  survey?: Survey
): Promise<Survey | undefined> {
  return await openDialog<Survey | undefined, Survey>({
    aid: (survey?.id ? 'EDIT' : 'ADD') + '_SURVEY_DIALOG',
    headerText: strings.dialogTitleDetails,
    component: AddEditSurveyDialog,
    showTopSeparator: !!survey?.id,
    input: clone(survey), // guard the original by cloning
  });
}

function clone(survey?: Survey): Survey | undefined {
  if (!survey) return undefined;

  return JSON.parse(JSON.stringify(survey));
}
