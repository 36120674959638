export function getSurveyResponseDomain() {
  return 'https://surveys.wisestamp.com';
}

export const getRawHTML = (tbody: string) => {
  return `<table width="100%" cellspacing="0" cellpadding="0"
  style="border-collapse:collapse;width:100%; 
  height:100%; text-align:center; border:none; margin:0; padding:0;">
  <tr>
    <td style="border:none;">
      <table width="500" cellspacing="0" cellpadding="0" align="center"
        style="margin: 0 auto; border-collapse:collapse;padding:10px 20px;border:none;max-width:500px;width:100%;border:none;text-align: left">
        <thead>
          <tr>
            <td style="border:none;">
                <!-- please enter your email header here -->
            </td>
          </tr>
        </thead>
        <tbody>
          <table width="100%" cellspacing="0" cellpadding="0"
            style="border-collapse:collapse;width:100%; height:100%; text-align:center; border:none; margin:0; padding:0;">
            <tr>
              <td style="border:none;">
                <table width="500" cellspacing="0" cellpadding="0" align="center"
                  style="border-collapse:collapse;margin:auto;padding:10px 20px;border:none;max-width:500px;width:100%;border:none;">
                  <tbody>
                    ${tbody}
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </tbody>
        <tfoot>
          <tr>
            <td style="border:none;">
                <!-- please enter your email footer here -->
            </td>
          </tr>
        </tfoot>
      </table>
    </td>
  </tr>
</table>
`;
};
