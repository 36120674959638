<script setup lang="ts">
import { ref } from 'vue';
import {
  IDialog,
  openDialog,
  WsButton,
  WsDialogActions,
  WsDialogForm,
  WsDialogSection,
  WsInput,
  WsRadio,
} from '@mfl/common-components';
import { Survey, surveysGateway } from '@msl/surveys-gateway-sdk';
import strings from './surveys.strings';
import DeleteLinkSurveyDialog from './delete-link-survey-dialog.vue';
import { getSurveyResponseDomain } from './utils';

enum linkTypes {
  default = 'default',
  custom = 'custom',
}

const props = defineProps<{
  dialog: IDialog<Survey | undefined, Survey>;
}>();

const busy = ref(false);
const survey = ref<Survey>({
  id: '',
  name: '',
  slug: '',
});
const initialSlug = ref<string>();
const linkType = ref<linkTypes>(linkTypes.default);
const hasExistingCustomLink = ref<boolean>(false);

if (props.dialog.input) {
  survey.value = props.dialog.input;
  hasExistingCustomLink.value = !!survey.value.linkId;
  linkType.value = props.dialog.input.slug
    ? linkTypes.custom
    : linkTypes.default;
  initialSlug.value = props.dialog.input.slug;
}

async function save() {
  busy.value = true;
  try {
    if (survey.value.id) await updateSurvey();
    else await createSurvey();
  } catch (e) {
    // TODO: inform the user of the failure and allow him/her to try again
    console.error('error saving survey', e);
  } finally {
    busy.value = false;
  }
}

async function createSurvey() {
  const { createdAt, id, linkURL } = await surveysGateway.create({
    name: survey.value.name,
    type: survey.value.type,
  });

  if (createdAt) {
    props.dialog.close({
      id,
      name: survey.value.name,
      type: survey.value.type,
      createdAt,
      linkId: survey.value.linkId,
      linkURL,
    });
  } else {
    console.error('create survey failed');
    // TODO: inform the user
  }
}

async function updateSurvey() {
  try {
    const isChangingToDefault =
      hasExistingCustomLink.value && linkType.value === linkTypes.default;
    const isLinkTypeCustom = linkType.value === linkTypes.custom;

    if (isChangingToDefault) {
      const dialogResponse = await openDialog({
        aid: 'DELETE_LINK_SURVEY_DIALOG',
        component: DeleteLinkSurveyDialog,
        headerText: '',
        showTopSeparator: false,
        input: 'Disabling custom link will remove any associated data.',
        persistent: true,
      });

      if (!dialogResponse) return;
    }

    let updatedLink;

    if (isChangingToDefault) {
      await surveysGateway.deleteSurveyLink({ linkId: survey.value.linkId });
    } else if (
      isLinkTypeCustom &&
      (!survey.value.slug || initialSlug.value !== survey.value.slug)
    ) {
      updatedLink = await surveysGateway.updateSurveyLink({
        surveyId: survey.value.id,
        slug: survey.value.slug,
        hasExistingCustomLink: hasExistingCustomLink.value,
        title: survey.value.name,
        linkId: survey.value.linkId,
      });
    }

    const updatedSurvey = await surveysGateway.updateSurvey({
      id: survey.value.id,
      name: survey.value.name,
      linkType: linkType.value,
      linkId: updatedLink?.link?.id || '',
      linkURL: updatedLink?.link?.fullShortLinkURL || '',
    });

    props.dialog.close({
      linkId: isLinkTypeCustom ? updatedLink?.link?.id : '',
      name: updatedSurvey.survey?.name,
      slug: isLinkTypeCustom
        ? survey.value.slug || updatedLink?.link?.slug
        : '',
      linkURL: updatedSurvey.survey?.linkURL || '',
    });
  } catch (error) {
    console.error(error);
    // TODO: inform the user
  }
}

async function validateSlug(val: string) {
  if (!val) return true;

  if (!/^[a-zA-Z0-9-]{1,20}$/.test(val)) {
    return strings.slugValidationMessage;
  }

  try {
    const res = await surveysGateway.isSlugExists({ slug: val });
    return (
      !res.linkId ||
      (survey.value.linkId && survey.value.linkId === res.linkId) ||
      strings.slugUniqueMessage
    );
  } catch {
    return strings.generalError;
  }
}
</script>

<template>
  <WsDialogForm @submit="save">
    <WsDialogSection
      :class="['survey-form-section', !survey.id ? 'create-section' : '']"
    >
      <div class="survey-name-section">
        <label v-if="survey.id" for="SURVEY_DIALOG_NAME">{{
          strings.name
        }}</label>
        <WsInput
          v-model="survey.name"
          aid="SURVEY_DIALOG_NAME"
          size="md"
          :placeholder="strings.name"
          autofocus
        />
      </div>
      <div v-if="survey.id" class="share-header">Share Link</div>
      <div v-if="survey.id" class="link-section">
        <WsRadio
          v-model="linkType"
          class="radio"
          value="default"
          label="Default Link"
          aid="DEFAULT_LINK"
        />
        <WsInput
          :model-value="`${getSurveyResponseDomain()}/s1/${survey.id}?source=link`"
          aid="SURVEY_DIALOG_DEFAULT_LINK"
          size="md"
          disabled
        />
        <WsRadio
          v-model="linkType"
          class="radio"
          value="custom"
          label="Custom Link"
          aid="CUSTOM_LINK"
        />
        <div class="link-slug-section">
          <WsInput
            aid="CUSTOM_LINK_URL_PREFIX"
            size="md"
            model-value="wisestamp.link/"
            disabled
          />
          <WsInput
            v-model="survey.slug"
            aid="SURVEY_DIALOG_SLUG"
            debounce="500"
            size="md"
            :disabled="linkType === linkTypes.default"
            :rules="[validateSlug]"
          />
        </div>
      </div>
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="SURVEY_DIALOG_CLOSE_BUTTON"
        label="Cancel"
        color="gray-500"
        variant="outlined"
        @click="props.dialog.close()"
      />
      <WsButton
        type="submit"
        color="primary"
        aid="SURVEY_DIALOG_SUBMIT_BUTTON"
        :label="strings.dialogSaveButton"
        :loading="busy"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style scoped lang="scss">
.survey-form-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 560px;
  width: 560px;
  font-size: 13px;
  padding: 24px;
}

.survey-name-section > :nth-child(2) {
  margin-top: 6px;
}

.share-header {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 16px;
}

.link-section {
  display: flex;
  flex-direction: column;
  gap: 11px;

  label {
    padding-bottom: 0px;
  }

  .link-slug-section {
    > .q-field--disabled:first-child {
      min-width: 140px;
      --ws-input-border-radius: 3px 0 0 3px;
      border-left: 0;

      *:disabled {
        color: #2d2e30;
        font-weight: 400;
      }

      > :first-child {
        > :first-child::before {
          background-color: rgb(var(--color-primary-50));
          border-right: 0;
        }

        > :first-child > :first-child {
          opacity: 1 !important;
        }
      }
    }

    > :nth-child(2) {
      width: 100%;
      --ws-input-border-radius: 0 3px 3px 0;

      > :first-child > :first-child > :nth-child(2) {
        color: rgb(var(--color-primary));
      }
    }

    display: flex;
  }
}

.create-section {
  padding: 14px 24px;
  width: 400px;

  .survey-name-section,
  .q-field--with-bottom {
    padding-bottom: 0px;
  }
}
</style>
