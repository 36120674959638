<script setup lang="ts">
import { ref, watch } from 'vue';
import {
  IDialog,
  WsDialogForm,
  WsDialogSection,
  WsSelectOption,
} from '@mfl/common-components';
import { Survey, SourceType } from '@msl/surveys-gateway-sdk';
import strings from './surveys.strings';
import emailEmbedTemplate from './email-embed-template.vue';
import { WsSelect, WsCheckbox } from '@mfl/common-components';

const props = defineProps<{
  dialog: IDialog<
    { survey: Survey; logo: string } | undefined,
    { survey: Survey; logo: string }
  >;
}>();

const survey = ref<Survey>({
  id: '',
});
const logo = ref<string>('');
const source = ref(SourceType.zendesk);
const sourceList = [
  { type: SourceType.zendesk, label: strings.zendesk },
  { type: SourceType.customer_io, label: strings.customerIO },
  { type: SourceType.vitally, label: strings.vitally },
  { type: SourceType.embed, label: strings.generalEmbed },
];
const isIdentified = ref<boolean>(true);

watch(source, () => {
  isIdentified.value = source.value !== SourceType.embed;
});

if (props.dialog.input) {
  survey.value = props.dialog.input.survey;
  logo.value = props.dialog.input.logo;
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="survey-form-section">
      {{ strings.dialogTextEmbed }}
      <div class="sources">
        <WsSelect
          v-model="source"
          aid="EDITOR_HEADER_WIDGWT_SELECT"
          variant="outlined"
          size="md"
          :option-key="
            (sourceType) =>
              sourceList.find((item) => item.type === sourceType)?.type ?? ''
          "
          :option-label="
            (sourceType) =>
              sourceList.find((item) => item.type === sourceType)?.label ?? ''
          "
          :update:model-value="
            (sourceType: SourceType) => (source = sourceType)
          "
        >
          <WsSelectOption
            v-for="sourceItem in sourceList"
            :key="sourceItem.label"
            :value="sourceItem.type"
          >
            {{ sourceItem.label }}
          </WsSelectOption>
        </WsSelect>
        <WsCheckbox
          v-model="isIdentified"
          aid="IDENTIFIED_SURVEY_CHECKBOX"
          :label="strings.identifiedSurvey"
          :disabled="source === SourceType.embed"
        />
      </div>
      <emailEmbedTemplate
        :source="SourceType[source]"
        :is-identified="isIdentified"
        :type="survey.type"
        :link-url="survey.linkURL || ''"
        :logo="logo || ''"
      ></emailEmbedTemplate>
    </WsDialogSection>
  </WsDialogForm>
</template>

<style scoped lang="scss">
.survey-form-section {
  display: flex;
  flex-direction: column;
  max-width: 560px;
  width: 560px;
  font-size: 13px;
}

.sources {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  color: rgb(var(--color-gray-400));
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  margin: 14px 0;

  & > * {
    width: 100%;
  }
}

.source {
  padding: 10px 14px;
  cursor: pointer;
}

.active-source {
  color: rgb(var(--color-gray-500));
  font-weight: 700;
  border-bottom: 2px solid rgb(var(--color-primary));
}
</style>
