<script setup lang="ts">
import { ref } from 'vue';

import { watch } from 'vue';
import strings from './surveys.strings';
import { getRawHTML } from './utils';

const props = defineProps<{
  logo: string;
  platformSpecific: string;
  linkUrl: string;
}>();
const { logo, linkUrl } = props;
const body = ref<string>('');
watch(
  () => props.platformSpecific,
  () => {
    getBody();
  }
);

const getBody = () => {
  body.value = `<div class="es-wrapper" style="width:100%;
                  height:100%;
                  display:table; table-layout:fixed; padding: 0; margin: 0; font-family:Mulish, Helvetica, Arial, sans-serif;">
                  <div class="es-content" style="padding: 20px; box-sizing: border-box; text-align: center; margin:auto;">
                    <div style="text-align: center; margin-bottom: 20px;">
                      <img src="${logo}" alt="${strings.logoAlt}" style="display:block; border:0; outline:none; text-decoration:none; -ms-interpolation-mode:bicubic; margin:auto;" width="130">
                    </div>
                    <h1 style="font-size:30px; color:#08415C; line-height:45px; margin:20px 0 10px 0;"><b>${strings.yourFeedbackIsImportant}</b></h1>
                    <h2 style="font-size:20px; color:#08415C; line-height:30px; margin:0 0 20px 0;"><b>${strings.joinUsersWhoAnswered}</b></h2>
                    <h3 style="font-weight:bold; font-size:20px; color:#08415C; margin:0 0 20px 0;">${strings.howLikelyAreYouRecommendUs}</h3>
                    <div style="text-align:center;">
                      <table role="presentation" style="border:none;margin:auto;width:fit-content; border-collapse: collapse; padding: 0;">
                        <tr>
                          ${[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                            .map((rating) => {
                              let color = '#FFDDE1';
                              let textColor = '#D00619';
                              if ([7, 8].includes(rating)) {
                                color = '#FBEED4';
                                textColor = '#F2A200';
                              } else if ([9, 10].includes(rating)) {
                                color = '#D2FADF';
                                textColor = '#4EC678';
                              }
                              return `<td style="border:none;">
                <div style="background-color:${color}; border:none; border-radius:25px; width:32px; height:32px; text-align:center; min-width:20px; min-height:20px; padding: 2.5px;">
                                <a href="${linkUrl}?rating=${rating}${props.platformSpecific}" target="_blank" rel="noopener" style="font-size:18px; font-weight:bold; color:${textColor}; text-decoration:none; display:block; width:32px; height:32px; line-height:32px;margin:auto;">${rating}</a>
                              </div>
                              </td>`;
                            })
                            .join('')}
                          </tr>
                      </table>
                    </div>
                    <p style="font-size:18px; color:#08415C; line-height:20px; margin-top:40px;">${strings.thankYouForYourTimeAndFeedback}</p>
                  </div>
                </div>`;
};
</script>

<template>
  {{ getRawHTML(body) }}
</template>
